<template>
  <div>
    <div class="tab-content" v-loading.fullscreen.lock="fullscreenLoading">
      <h2 class="column-title">基础信息</h2>
      <el-form
        ref="baseInfoForm"
        size="medium"
        :model="detailData"
        label-width="200px"
      >
        <el-form-item :label="labelText('sds_type')">
          <el-select
            v-model="detailData.sds_type"
            :placeholder="selectPlaceholderText"
            :disabled="pageStatus == 'Detail'"
            style="width: 100%"
          >
            <el-option
              v-for="(item, idx) in sdsTypeList"
              :label="item.name"
              :value="item.value"
              :key="idx"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="labelText('print_title')" v-if="language == 'CN'">
          <el-input
            v-model="detailData.print_title"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="labelText('supplier_or_producer')"
          v-if="language == 'CN'"
        >
          <el-input
            v-model="detailData.supplier_or_producer"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('item_code')">
          <el-autocomplete
            v-model="detailData.item_code"
            :fetch-suggestions="querySearch"
            @select="itemCodeBlurHandle"
            @blur="itemCodeBlurHandle"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
            style="width: 100%"
          >
            <template v-if="checkItemCode">
              <el-button
                slot="append"
                type="warning"
                :loading="checkItemCodeLoading"
                style="border: 1px solid red; color: red"
                >{{ itemCodeStatusText }}</el-button
              >
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item :label="labelText('product_name')">
          <el-input
            v-model="detailData.product_name"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('version_number')">
          <el-input
            v-model="detailData.version_number"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('customer_product_name')">
          <el-input
            v-model="detailData.customer_product_name"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('botanical_name')">
          <el-input
            v-model="detailData.botanical_name"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('inci_name')">
          <el-input
            v-model="detailData.inci_name"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('appearance')">
          <el-input
            v-model="detailData.appearance"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('ph')">
          <el-input
            v-model="detailData.ph"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('color')">
          <el-input
            v-model="detailData.color"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('solubility')">
          <el-input
            v-model="detailData.solubility"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('odor')">
          <el-input
            v-model="detailData.odor"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('specific_gravity')">
          <el-input
            v-model="detailData.specific_gravity"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('storage')">
          <el-input
            v-model="detailData.storage"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('customs_classification')">
          <el-input
            v-model="detailData.customs_classification"
            :disabled="pageStatus == 'Detail'"
            :placeholder="placeholderText"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>COMPOSITION/INFORMATION ON INGREDIENTS</span>
          <el-button
            class="add-row-button"
            size="mini"
            type="primary"
            plain
            icon="el-icon-circle-plus-outline"
            :disabled="pageStatus == 'Detail'"
            @click="addRowDataHandle()"
            >Add Row</el-button
          >
        </div>
        <div>
          <el-form
            ref="baseInfoForm"
            size="medium"
            label-width="200px"
            v-for="(
              item, index
            ) in detailData.composition_information_on_ingredients"
            :key="index"
          >
            <el-form-item :label="labelText('substance_name')">
              <el-autocomplete
                popper-class="my-autocomplete"
                :disabled="pageStatus == 'Detail'"
                v-model="item.substance_name"
                :fetch-suggestions="substanceNameQuerySearch"
                :placeholder="placeholderText"
                style="width: 100%"
                @focus="substanceNameFocus(index)"
                @select="substanceNameHandleSelect">
                <i
                  class="el-icon-circle-plus el-input__icon"
                  slot="suffix"
                  @click="substanceNameAddIconClick(index)">
                </i>
                <template slot-scope="{ item }">
                  <div class="name">{{ item.value }}&nbsp;&nbsp;<el-button type="danger" size="mini" icon="el-icon-delete" circle style="float:right;" @click="substanceNameDeleteIconClick(index, item.id)"></el-button></div>
                </template>
              </el-autocomplete>
              
            </el-form-item>
            <el-form-item :label="labelText('cas_number')">
              <el-input
                :placeholder="placeholderText"
                :disabled="pageStatus == 'Detail'"
                v-model="item.cas_number"
              ></el-input>
            </el-form-item>
            <el-form-item :label="labelText('einecs_number')">
              <el-input
                :placeholder="placeholderText"
                :disabled="pageStatus == 'Detail'"
                v-model="item.einecs_number"
              ></el-input>
            </el-form-item>
            <el-form-item :label="labelText('r_phrase')">
              <el-input
                :placeholder="placeholderText"
                :disabled="pageStatus == 'Detail'"
                v-model="item.r_phrase"
              ></el-input>
            </el-form-item>
            <el-form-item :label="labelText('s_phrase')">
              <el-input
                :placeholder="placeholderText"
                :disabled="pageStatus == 'Detail'"
                v-model="item.s_phrase"
              ></el-input>
            </el-form-item>
            <div>
              <el-button
                icon="el-icon-circle-plus-outline"
                circle
                v-if="index >= 1"
                @click="insertRow(index)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                v-if="index >= 1"
                @click="removeRow(index)"
              ></el-button>
            </div>
            <el-divider></el-divider>
          </el-form>
        </div>
      </el-card>
      <div class="revision-container">
        <RevisionComponents
          :revisionList="detailData.revision"
          :pageStatus="pageStatus"
          :language="language"
        ></RevisionComponents>
      </div>
      <div class="footer-bottom">
        <div style="margin-top: 20px">
          <el-button
            :disabled="buttonDisable"
            :loading="updateFromSpecLoading"
            @click="updateFromSpecHandle()"
            >Update From Spec <i class="el-icon-discount"></i
          ></el-button>

          <el-button
            type="warning"
            :disabled="buttonDisable"
            :loading="verifyListLoading"
            @click="addVerifyListHandle('ContinueEdit', 1)"
            >Add to Verify List</el-button
          >
          <el-button
            type="primary"
            :disabled="buttonDisable"
            :loading="saveLoading"
            @click="saveHandle()"
            >Save</el-button
          >
          <el-button
            type="warning"
            :disabled="buttonDisable"
            @click="vertifyHandle()"
            :loading="verifyLoading"
            >Vertify</el-button
          >
          <el-button @click="back()">Back Home</el-button>
          <el-button
            type="success"
            icon="el-icon-printer"
            :loading="printLoading"
            @click="previewHandle()"
            >Print</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RevisionComponents from "../../spec/components/RevisionComponents.vue";
import { apiServer } from "@/network/modules/detail-api";
import * as newApi from "@/network/new-api";
var dayjs = require("dayjs");
export default {
  components: {
    RevisionComponents,
  },
  props: {
    language: String,
    pageStatus: String,
    detailData: Object,
    specData: Object,
  },
  data() {
    return {
      checkItemCode: false,
      checkItemCodeLoading: false,
      itemCodeStatusText: "",
      verifyLoading: false,
      saveLoading: false,
      verifyListLoading: false,
      printLoading: false,
      rowData: [], // 存储行数据
      fullscreenLoading: false,
      updateFromSpecLoading: false,
      restaurants: [],
      substanceNameRestaurants: [],
      substanceNameFocusIndex: 0,
    };
  },
  computed: {
    sdsTypeList() {
      // 根据语言获取 SDS 类别列表
      if (this.$route.query.language === "EN") {
        return [
          { name: "Liquid", value: "Liquid" },
          { name: "Oil", value: "Oil" },
          { name: "Powder", value: "Powder" },
        ];
      }
      return [
        { name: "TY-L", value: "TY-L" },
        { name: "TY-Oil", value: "TY-Oil" },
        { name: "TY-P", value: "TY-P" },
      ];
    },
    selectPlaceholderText() {
      // 获取选择框的占位符文本
      return this.$route.query.language === "EN" ? "Please Select" : "请选择";
    },
    labelText() {
      return (label) => {
        // console.info('this.zdItem:',this.zdItem)
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    placeholderText() {
      // 获取输入框的占位符文本
      return this.$route.query.language === "EN" ? "Please Input" : "请输入";
    },
    buttonDisable() {
      // 判断按钮是否禁用
      return this.pageStatus === "Detail";
    },
  },
  methods: {
    loadSubstanceNameList() {
      console.info("loadSubstanceNameList:",newApi.default.substanceName);
      newApi.default.substanceName.allList({}).then(data => {
        this.substanceNameRestaurants = data.map(item => ({
          value: item.substanceName,
          casNumber: item.casNumber,
          einecsNumber: item.einecsNumber,
          rPhrase: item.rPhrase,
          sPhrase: item.sPhrase,
          id: item.id
        }));
      });
    },
    substanceNameAddIconClick(index) {
      console.info("substanceNameAddIconClick:", index);
      let tmp = this.detailData.composition_information_on_ingredients[index];
      if (!tmp.cas_number || !tmp.einecs_number || !tmp.r_phrase || !tmp.s_phrase) {
        this.$message.error('CAS Number、EINECS Number、R Phrase、S Phrase 不能为空');
        return;
      }
      newApi.default.substanceName.add({
        substanceName: tmp.substance_name,
        casNumber: tmp.cas_number,
        einecsNumber: tmp.einecs_number,
        rPhrase: tmp.r_phrase,
        sPhrase: tmp.s_phrase,
      }).then(res => {  
        console.info("res:", res);
        //添加成功
        this.$message.success('添加成功');
        this.loadSubstanceNameList();
      });
    },
    substanceNameDeleteIconClick(index, id) {
      this.detailData.composition_information_on_ingredients[index]=[{"value":"","casNumber":"","einecsNumber":"","rPhrase":"","sPhrase":""}]
      console.info("substanceNameDeleteIconClick:", index, id);
      newApi.default.substanceName.delete(id).then(res => {
        console.info("res:", res);
        this.$message.success('删除成功');
        this.loadSubstanceNameList();
      });
    },
    substanceNameQuerySearch(queryString, cb) {
      var tmp = this.substanceNameRestaurants;
      var results = queryString ? tmp.filter(this.substanceNameCreateFilter(queryString)) : tmp;
        // 调用 callback 返回建议列表的数据
      cb(results);
    },
    substanceNameCreateFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
    substanceNameFocus(index) {
      this.substanceNameFocusIndex = index;
      console.info("substanceNameFocus:", index);
    },
    substanceNameHandleSelect(item) {
      let index = this.substanceNameFocusIndex;
      this.detailData.composition_information_on_ingredients[index].value = item.value;
      this.detailData.composition_information_on_ingredients[index].cas_number = item.casNumber;
      this.detailData.composition_information_on_ingredients[index].einecs_number = item.einecsNumber;
      this.detailData.composition_information_on_ingredients[index].r_phrase= item.rPhrase;
      this.detailData.composition_information_on_ingredients[index].s_phrase = item.sPhrase;
    },
    itemCodeBlurHandle() {
      if (this.detailData.item_code.length == 0) {
        return;
      }
      // 检查 itemCode 是否已经存在
      this.itemCodeStatusText = this.$t("confirm.item_code_checking");
      this.checkItemCode = true;
      this.checkItemCodeLoading = true;
      apiServer.getDataByType(
        "sds",
        "itemCodeInfo",
        { itemCode: this.detailData.item_code },
        (res) => {
          this.checkItemCodeLoading = false;
          if (res.jsonValue) {
            this.itemCodeStatusText = this.$t(
              "confirm.item_code_already_exists"
            );
          } else {
            this.checkItemCode = false;
          }
        }
      );
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    loadItemCodeList() {
      const language = this.$route.query.language;
      const storageKey = `itemCodeList_${language}`;
      const storedItemCodeList = localStorage.getItem(storageKey);
      if (storedItemCodeList == null) {
        // 如果本地存储无效或解析失败,从服务器获取
        apiServer.getDataByType("spec", "getAuditedItemCodeList", {}, (res) => {
          const itemCodeList = res.map((obj) => ({ value: obj.itemCode }));
          this.restaurants = itemCodeList;
          localStorage.setItem(storageKey, JSON.stringify(itemCodeList));
        });
      }

      try {
        if (storedItemCodeList) {
          const parsedList = JSON.parse(storedItemCodeList);
          if (Array.isArray(parsedList) && parsedList.length > 0) {
            this.restaurants = parsedList;
            return;
          }
        }
      } catch (e) {
        console.error("解析本地存储的itemCodeList失败:", e);
      }

      // 如果本地存储无效或解析失败,从服务器获取
      apiServer.getDataByType("spec", "itemCodeList", {}, (res) => {
        const itemCodeList = res.map((obj) => ({ value: obj.itemCode }));
        this.restaurants = itemCodeList;
        localStorage.setItem(storageKey, JSON.stringify(itemCodeList));
      });
    },
    sdsTypeChange(value) {
      console.info("value:", value);
      this.detailData.sds_type = value;
    },
    vertifyHandle() {
      this.$confirm(this.$t("confirm.verify_tips"), this.$t("confirm.tips"), {
        confirmButtonText: this.$t("confirm.ok"),
        cancelButtonText: this.$t("confirm.cancel"),
        distinguishCancelAndClose: true,
        type: "warning",
      }).then(() => {
        let id = "";
        if (this.$route.query.isCopy != "true") {
          id = this.$route.query.id;
        }
        //验证
        let revision_list = localStorage.getItem("revision_list");
        let revision_lists = JSON.parse(revision_list);
        let verifyModel = {};
        let msg = "";
        if (revision_lists.length == 0) {
          msg = this.$t("confirm.no_revision_data");
        } else {
          console.info("revision_list---1:", revision_list);
          verifyModel = revision_lists[revision_lists.length - 1];

          if (verifyModel.requested_by.length == 0) {
            msg = this.$t("confirm.requested_by_empty");
          }
          if (verifyModel.items_revised.length == 0) {
            msg = this.$t("confirm.items_revised_empty");
          }
          if (verifyModel.edited_by.length == 0) {
            msg = this.$t("confirm.edited_by_empty");
          }
          if (verifyModel.reason.length == 0) {
            msg = this.$t("confirm.reason_empty");
          }
          if (msg != "") {
            this.$message({
              showClose: true,
              message: msg,
              type: "error",
            });
            return;
          }
        }

        this.verifyLoading = true;

        verifyModel.isComplete = true;
        verifyModel.approvedBy = localStorage.getItem("loginUserNameKey");
        verifyModel.approved_by = verifyModel.approvedBy;
        verifyModel.revisionDate = verifyModel.revision_date;

        let tmpDetailData = JSON.parse(JSON.stringify(this.detailData));
        tmpDetailData.revision = JSON.parse(revision_list);

        console.info(" this.detailData.revision：", this.detailData.revision);
        tmpDetailData.revision[tmpDetailData.revision.length - 1] = verifyModel;

        console.info(">>>>>>tmpDetailData:", tmpDetailData);
        let params = {
          id: id,
          itemCode: this.detailData.item_code,
          sdsType: this.detailData.sds_type,
          productName: this.detailData.product_name,
          revNumber: revision_list.length,
          jsonValue: JSON.stringify(tmpDetailData),
        };
        console.info("params:", params);
        console.info("this.detailData.revNumber:", tmpDetailData.revNumber);

        let type = this.$route.query.type;
        apiServer.getDataByType(type, "verify", params, () => {
          this.verifyLoading = false;
          this.$message({
            showClose: true,
            message: this.$t("confirm.verify_success"),
            type: "success",
          });
          location.reload();
        });
      });
    },
    updateFromSpecHandle() {
      this.loadSpecInfoByItemCode(this.detailData.item_code);
    },
    itemCodeChange(val) {
      this.loadSpecInfoByItemCode(val);
    },
    loadSpecInfoByItemCode(itemCode) {
      // this.fullscreenLoading = true;
      this.updateFromSpecLoading = true;
      // 从 spec 获取关联数据
      apiServer.getDataByType(
        "spec",
        "auditedLatestInfo",
        { itemCode },
        (res) => {
          console.info("res:", res);

          if (res.jsonValue) {
            const specModel = JSON.parse(res.jsonValue);
            // 设置默认值
            this.$emit("load-spec-item-code", specModel);
            this.$message({
              message: this.$t("confirm.import_success"),
              type: "success",
            });
          } else {
            this.updateFromSpecLoading = false;
            this.$message({
              message: this.$t("confirm.no_verified_item_code"),
              type: "error",
            });
          }
          this.updateFromSpecLoading = false;
        }
      );
    },
    insertRow(idx) {
      let row = this.initRowData();
      this.detailData.composition_information_on_ingredients.splice(
        idx + 1,
        0,
        row
      );
    },
    // 添加行数据
    addRowDataHandle() {
      this.detailData.composition_information_on_ingredients.push(
        this.initRowData()
      );
    },
    initRowData() {
      return {
        substanceName: "",
        casNumber: "",
        einecsNumber: "",
        rPhrase: "",
        sPhrase: "",
      };
    },
    // 删除行数据
    removeRow(idx) {
      this.detailData.composition_information_on_ingredients.splice(idx, 1);
    },
    addVerifyListHandle() {
      this.$confirm(this.$t("confirm.verify_tips"), this.$t("confirm.tips"), {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        // let data = this.getFormDataMethod();
        const flag = this.checkRevisionList();
        if (!flag) {
          return;
        }

        let id = "";
        if (this.$route.query.isCopy != "true") {
          id = this.$route.query.id;
        }
        let revision_list = localStorage.getItem("revision_list");
        this.detailData.revision = JSON.parse(revision_list);
        let params = {
          id: id,
          itemCode: this.detailData.item_code,
          sdsType: this.detailData.sds_type,
          productName: this.detailData.product_name,
          revNumber: this.detailData.rev_number,
          jsonValue: JSON.stringify(this.detailData),
        };

        let type = this.$route.query.type;
        this.verifyListLoading = true;
        apiServer.getDataByType(type, "addVerifyList", params, () => {
          this.verifyListLoading = false;
          this.$message({
            type: "success",
            message: "verifyList Success",
          });
        });
      });
    },
    saveHandle() {
      let id = "";
      let revision_list = localStorage.getItem("revision_list");
      this.detailData.revision = JSON.parse(revision_list);
      if (this.$route.query.isCopy != "true") {
        id = this.$route.query.id;
      }

      console.info("revision_list:", revision_list);
      let params = {
        id: id,
        itemCode: this.detailData.item_code,
        sdsType: this.detailData.sds_type,
        productName: this.detailData.product_name,
        revNumber: this.detailData.rev_number,
        jsonValue: JSON.stringify(this.detailData),
      };
      this.saveLoading = true;
      this.$emit("save-sds", params, (data) => {
        this.saveLoading = false;
        this.pageStatus = localStorage.getItem("pageStatus");
        if (this.pageStatus == "Add") {
          console.info("data:", data.id);
          // this.pageStatus = "Edit";
          this.$emit("change-page-status", "Edit");
          let language = localStorage.getItem("language");
          this.$router.replace({
            path: "/new-sds",
            query: {
              id: data.id,
              type: "sds",
              isCopy: "false",
              language: language,
            },
          });
        }
        if (
          this.$route.query.isCopy == "true" ||
          this.$route.query.isCopy == true
        ) {
          setTimeout(() => {
            this.$router.go(-1);
          }, 200);
        }
      });
    },
    saveValidate() {
      let msg = "";

      if (!this.detailData.itemCode) {
        msg =
          this.language === "CN"
            ? "请输入产品编码!"
            : "Please Input Item Code!";
      } else if (!this.detailData.sdsType) {
        msg =
          this.language === "CN" ? "请输入SDS类别!" : "Please Input SDS Type!";
      } else if (!this.detailData.productName) {
        msg =
          this.language === "CN"
            ? "请输入产品名称!"
            : "Please Input Product Name!";
      }

      if (msg) {
        this.$message.error(msg);
        return false;
      }
      return true;
    },
    checkRevisionList() {
      // let isVerify = false;

      let revision_list = JSON.parse(localStorage.getItem("revision_list"));
      if (revision_list.length > 0) {
        let revision = revision_list[revision_list.length - 1];
        console.info("revision:", revision);
        let msg = "";
        if (revision.revision_date.length == 0) {
          msg = this.$t("confirm.no_revision_date");
        } else if (revision.requested_by.length == 0) {
          msg = this.$t("confirm.requested_by_empty");
        } else if (revision.items_revised == 0) {
          msg = this.$t("confirm.items_revised_empty");
        } else if (revision.edited_by == 0) {
          msg = this.$t("confirm.edited_by_empty");
        } else if (revision.reason == 0) {
          msg = this.$t("confirm.reason_empty");
        }
        if (msg != "") {
          this.$message({
            type: "error",
            message: msg,
          });
          return false;
        }
      }
      return true;
    },
    // 验证并处理
    vertifySdsHandle() {
      if (!this.saveValidate()) {
        return false;
      }

      const msg = this.checkRevisionList();
      if (msg !== "") {
        this.$message({
          type: "error",
          message: msg,
        });
        return;
      }

      this.callApi();
    },
    getMessage(enMsg, cnMsg) {
      // 获取对应语言的消息
      return this.language === "CN" ? cnMsg : enMsg;
    },
    callApi() {
      let api = "";
      let params = {};

      if (this.language === "CN") {
        api = this.$api.handleNewSds.verfiedNewCnSds(params);
      } else {
        api = this.$api.handleNewSds.verfiedNewSds(params);
      }

      api
        .then(() => {
          this.verifyLoading = false;
          this.$message({
            type: "success",
            message: "Update Success",
          });
        })
        .catch((err) => {
          this.verifyLoading = false;
          console.log(err);
        });
      apiServer.getDataByType("sds", "addVerifyList", params, () => {
        this.verifyLoading = false;
        this.$message({
          message: this.$t("confirm.save_success"),
          type: "success",
        });
      });
    },
    back() {
      this.$router.go(-1);
    },
    previewHandle() {
      this.printLoading = true;
      let jsonValue = JSON.stringify(this.detailData);

      let printData = {
        item_code: this.detailData.item_code,
        sds_type: this.detailData.sds_type,
        product_name: this.detailData.product_name,
        rev_number: this.detailData.rev_number,
        create_time: this.detailData.create_time ?? "",
        update_time: this.detailData.update_time ?? "",
        create_name: this.detailData.create_name ?? "",
        update_name: this.detailData.update_name ?? "",
        verified_list: this.detailData.verified_list ?? "",
        verified_list_user: this.detailData.verified_list_user ?? "",
        verified: this.detailData.verified ?? "",
        verified_time:
          this.detailData.verified_time ?? dayjs().format("DD-MM-YYYY"),
        verified_name: this.detailData.verified_name ?? "",
        json_value: jsonValue,
      };
      let data = this.detailData;

      let versionNumber = "";
      let previewPdf = true; // 编辑状态为 true
      if (this.pageStatus === "Detail") {
        previewPdf = false;
        if (typeof data.revNumber != "undefined") {
          versionNumber = "V_" + data.revNumber + ".0";
        } else {
          versionNumber = "V_1.0";
        }
      }

      let languageType = "US";
      if (this.language === "CN") {
        languageType = "China";
      }
      console.info("printData:", printData);

      let fileName = `${data.product_name}_${data.item_code}_${versionNumber}`;
      fileName = fileName.replace(/[%<>:"/\\|?*]/g, "");

      if (previewPdf) {
        if (languageType == "China") {
          fileName = "(Preview 中文 SDS)" + fileName;
        } else {
          fileName = "(Preview SDS)" + fileName;
        }
      } else {
        if (languageType == "China") {
          fileName = "(中文 SDS)" + fileName;
        } else {
          fileName = "(SDS)" + fileName;
        }
      }
      let params = {
        print_type: "sds",
        language: languageType,
        data: printData,
        preview_pdf: previewPdf,
        file_name: fileName, // item_code+version+signature
        response_type: "text",
        verified_time: printData.verified_time,
      };

      this.previewPdfLoading = false;
      this.$axios
        .post("https://spec.dracoherbs.com/flask/generate_spec", params, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          let fileUrl = `https://spec.dracoherbs.com/download/${response.data.file_name}`;

          this.printLoading = false;
          window.open(fileUrl);
        })
        .catch((error) => {
          this.printLoading = false;
          console.error(error);
        });
    },
  },
  created() {
    console.info("this.detailData==》》:", this.detailData);
    if (typeof this.detailData.revision == "undefined") {
      this.detailData.revision = [
        {
          approvedBy: "",
          editedBy: "",
          isComplete: false,
          reason: "",
          requestedBy: "",
          revisedNotes: "",
          revisionDate: "",
        },
      ];
    }
    this.loadItemCodeList();
    this.loadSubstanceNameList();
    // console.info("this.detailData==22》》:", this.detailData);
  },
};
</script>

<style scoped>
.add-row-button {
  float: right;
}
</style>